<template>
  <a-spin :spinning="spinShow">
    <a-form :form="form">
      <a-form-item label="日期" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-date-picker style="width: 100%" :showTime="{ format: 'YYYY-MM-DD HH:mm:ss' }"
                       format="YYYY-MM-DD HH:mm:ss"
                       v-decorator="['noteDate',{rules: [{ required: true, message: '请选择' }]}]"
                       placeholder="请选择"></a-date-picker>
      </a-form-item>
      <a-form-item label="所属部门" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-tree-select
          style="width: 100%"
          v-decorator="['departmentId' ,  {
                  rules: [{
                    required: true,
                    message: '请选择所属部门',
                  }]
                }]"
          :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
          :treeData="orgTree"
          allowClear
          placeholder="请选择所属部门"
        ></a-tree-select>
      </a-form-item>
      <a-form-item label="值班员" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-tree-select
          v-decorator="[
                'dutyUserId',
                {
                  validateTrigger: ['change'],
                  rules: [{
                    type: 'string',
                    required: true,
                    message: '请选择值班员',
                  }]
                }
              ]"
          treeDefaultExpandAll
          treeNodeFilterProp="title"
          style="width: 100%"
          :dropdownStyle="{ maxHeight: '400px', overflow: 'auto' }"
          :treeData="orgUserTreeData"
          placeholder="请选择值班员"
        ></a-tree-select>
      </a-form-item>
<!--      <a-form-item label="班组" :label-col="labelCol" :wrapper-col="wrapperCol">-->
<!--      </a-form-item>-->
      <a-form-item label="记事内容" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-textarea v-decorator="['noteContent']" rows="3" :maxLength="2500" placeholder="请输入记录内容"></a-textarea>
      </a-form-item>
      <a-form-item label="备注" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-textarea v-decorator="['remark']" rows="3" :maxLength="500" placeholder="请输入备注，最多输入500字"></a-textarea>
      </a-form-item>
    </a-form>
  </a-spin>
</template>

<script>
  import { formVO } from './common/common'
  import SERVICE_URLS from '@/api/service.url'
  import moment from 'moment'
  import entityCURDCallback from '../../common/mixins/entityCURDCallback'

  export default {
    name: 'Form',
    mixins: [entityCURDCallback],
    props: {
      type: {
        type: String,
        default: 'edit'
      }
    },
    mounted () {
      if (this.type === 'add') {
        this.loadCheckUserList()
        this.form.setFieldsValue({
          noteDate: moment(new Date())
        })
      }
      this.getOrgTree()
    },
    data () {
      return {
        entityBaseUrl: SERVICE_URLS.dutyNote,
        initFormItem: formVO(),
        showFormItem: false,
        spinShow: false,
        labelCol: { span: 5 },
        wrapperCol: { span: 17 },
        orgUserTreeData: [],
        orgTree: []
      }
    },
    computed: {
      currentUser () {
        return this.$store.getters.currentUser
      }
    },
    created () {
    },
    methods: {
      moment,
      getOrgTree () {
        this.$http(this, {
          url: SERVICE_URLS.organization.antTree,
          noTips: true,
          success: (data) => {
            this.orgTree = data.body
          }
        })
      },
      loadCheckUserList () {
        this.$http(this, {
          url: SERVICE_URLS.organization.orgUserTree,
          data: {
            authPrefix: 'org_',
            chooseType: 'RADIO'
          },
          noTips: true,
          success: (data) => {
            this.orgUserTreeData = data.body
            if (this.type === 'add') {
              /*默认设置执行人*/
              this.$nextTick(() => {
                const dutyUserKey = this.getSelectKey(this.orgUserTreeData, this.currentUser.id)
                if (dutyUserKey) {
                  this.form.setFieldsValue({
                    'dutyUserId': dutyUserKey
                  })
                }
              })
            } else {
              /*TODO 1.为啥在和处理？*/
              /*处理下拉树KEY*/
              var dutyUserKey = this.getSelectKey(this.orgUserTreeData, this.formItem.dutyUserId)
              if (dutyUserKey) {
                this.form.setFieldsValue({
                  'dutyUserId': dutyUserKey
                })
              }
            }
          }
        })
      },
      getSelectKey (list, id) {
        var key
        for (let i = 0; i < list.length; i++) {
          const item = list[i]
          const tempKey = item.key
          if (tempKey.substring('USER_') !== -1 && Number(tempKey.split('_')[2]) === id) {
            key = tempKey
            return key
          } else if (item.children.length > 0) {
            key = this.getSelectKey(item.children, id)
          }
          if (key) return key
        }
      },
      setFields (values) {
        Object.assign(this.formItem, values)
        this.loadCheckUserList()
        this.form.setFieldsValue({
          departmentId: this.formItem.departmentId,
          noteContent: this.formItem.noteContent,
          remark: this.formItem.remark,
          noteDate: moment(this.formItem.noteDate)
        })
      },
      setVOFields (values) {
        var dutyUserkey = values.dutyUserId.split('_')[2]
        this.formItem.dutyUserId = dutyUserkey
        this.formItem.departmentId = values.departmentId
        this.formItem.noteContent = values.noteContent
        this.formItem.remark = values.remark
        this.formItem.noteDate = values.noteDate
      }
    }
  }
</script>

<style scoped></style>
