export const columns = () => {
  return [
    {
      title: '序号',
      width: 50,
      align: 'center',
      scopedSlots: { customRender: '_index' }
    },
    {
      title: '所属部门',
      width: 150,
      ellipsis: true,
      align: 'center',
      dataIndex: 'departmentName'
    },
    // {
    //   title: '班组',
    //   width: 100,
    //   align: 'center',
    //   ellipsis: true,
    //   dataIndex: 'title'
    // },
    {
      title: '日期',
      width: 200,
      align: 'center',
      dataIndex: 'noteDate',
      scopedSlots: { customRender: 'noteDate' }
    },
    {
      title: '值班员',
      width: 100,
      align: 'center',
      dataIndex: 'dutyUsername',
    },
    {
      title: '记事内容',
      width: 400,
      align: 'left',
      dataIndex: 'noteContent',
      scopedSlots: { customRender: 'dateCreated' }
    },
    {
      title: '备注',
      align: 'left',
      width: 400,
      dataIndex: 'remark',
    },
    {
      title: '操作',
      width: 100,
      align: 'center',
      scopedSlots: { customRender: 'action' }
    }
  ]
}

export const formVO = () => {
  return {
    id: '',
    departmentId: '',
    dutyUserId: '',
    noteContent: '',
    noteDate: '',
    remark: '',
    teamId: ''
  }
}